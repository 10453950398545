@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=M+PLUS+1p:wght@400;800&display=swap");

:root {
  --backgroundColor: #ffffff;
  --backgroundInv: #00000050;
  --mainTextColor: #000000;
  --gradientColor1: #1fa2ff;
  --gradientColor2: #a1ffcb;
  --animeBoxColor: rgba(59, 219, 255, 0.355);
}

@media (prefers-color-scheme: dark) {
  :root {
    --backgroundColor: #222222;
    --backgroundInv: #ffffff;
    --mainTextColor: #ffffff;
    --gradientColor1: #b3b3b3;
    --gradientColor2: #333333;
    --animeBoxColor: #ffffff;
  }
}

* {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.flex {
  display: flex;
}

.hero {
  font-family: "M PLUS 1p", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: var(--backgroundColor);
  color: var(--mainTextColor);

  height: 100svh;
  display: flex;
  ul {
    // ナビゲーションバー 対策
    height: 100svh;
  }
  .profile {
    z-index: 3;
    display: flex;
    padding: 10px;
    margin: 0 auto;
    align-items: center;

    .description {
      h1 {
        font-weight: 800;
        font-size: 50px;
      }

      p {
        margin-top: -5px;
        color: gray;
      }
    }

    a {
      padding: 20px;
    }

    .MyIcon {
      width: 150px;
      height: auto;
      border-radius: 999px;
      box-shadow: 0 0 15px var(--backgroundInv);
    }
  }

  .arrow {
    display: flex;
    position: absolute;
    top: calc(100vh - 100px);
    right: 50%;
    font-size: 99px;
  }
}

.about {
  font-family: "M PLUS 1p", sans-serif;
  font-weight: 100;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0 0 100px var(--gradientColor1);

  padding: 25px 0;
  background: linear-gradient(
    270deg,
    var(--gradientColor1),
    var(--gradientColor2)
  );
  background-size: 200% 200%;
  animation: bggradient 20s ease infinite;

  a {
    color: var(--mainTextColor);
    text-decoration: underline;
  }

  .title {
    font-family: "Inter";
    font-weight: 800;
    font-style: normal;
    text-align: center;
  }

  .profile {
    background-color: rgba(255, 255, 255, 0.355);
    border-radius: 25px;
    padding: 30px;
    margin: 20px;

    width: 80%;
    max-width: 1000px;

    border: 1px solid #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.35);

    .main-prof {
      display: flex;
    }

    .skill {
      margin-top: 20px;
      ul {
        margin-left: 20px;
      }
    }

    .MyIcon {
      width: 100px;
      height: 100px;
      border-radius: 999px;
      margin-left: 50px;
    }
  }
}

.works {
  font-family: "M PLUS 1p", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 30px 0;
  background-color: var(--backgroundColor);
  color: var(--mainTextColor);

  .title {
    font-family: "Inter";
    font-weight: 800;
    font-style: normal;
    text-align: center;
    padding-top: 30px;
  }
  padding: 0 10px;
  .image-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
    &:first-child {
      margin-top: 20px;
    }
    .work {
      width: 390px;
      height: 220px;
      box-shadow: 0 0 15px rgba(128, 128, 128, 0.425);

      overflow: hidden;
      position: relative;

      margin: 10px;
      .caption {
        color: #000000;
        padding: 10px;
      }
      .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0; /* マスクを表示しない */
        background-color: #ffffff;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        h1 {
          font-size: 25px;
        }

        .links {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;
        }
        .lang {
          text-decoration: underline;
        }

        a {
          color: blue;
          text-decoration: underline;
        }
      }
      &:hover .mask {
        opacity: 1; /* マスクを表示する */
        padding: 0 5px; /* 右にずらす */
      }
    }
    img {
      width: 100%;
      align-items: center;
      object-fit: cover; /* 画像を枠内にフィットさせる */
    }
  }
}

.contact {
  background: linear-gradient(
    270deg,
    var(--gradientColor1),
    var(--gradientColor2)
  );
  background-size: 200% 200%;
  animation: bggradient 20s ease infinite;

  padding: 30px 0;
  .title {
    font-family: "Inter";
    font-weight: 800;
    font-style: normal;
    text-align: center;
  }

  .contacts {
    display: flex;
    justify-content: center;
    .contact-box {
      display: flex;
      font-family: "M PLUS 1p", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      align-items: center;

      background-color: rgba(255, 255, 255, 0.355);
      box-shadow: 0 0 15px rgba(128, 128, 128, 0.425);
      border-radius: 15px;
      padding: 10px;
      margin: 20px;
      width: 280px;

      &:hover {
        box-shadow: none;
        transition: all 0.3s;
      }

      svg {
        font-size: 40px;
        margin-right: 10px;
      }

      .text {
        text-align: left;
        flex-direction: column;
        line-height: 1.3rem;

        h1 {
          font-size: 20px;
        }
      }
    }
  }
}

// ---レスポンシブデザイン---
@media screen and (max-width: 800px) {
  .title {
    font-size: 22px;
  }
  .hero {
    padding-left: 0;
    justify-content: center;

    .profile {
      flex-direction: column;
      justify-content: center;

      .MyIcon {
        width: 100px;
        height: auto;
      }

      a {
        padding: 10px;
      }

      .description {
        margin-left: 0;
        margin-top: -10px;
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }

  .about {
    .profile {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 13px;
      }
    }
  }

  .works {
    .image-grid {
      .work {
        height: 440px;
        .mask {
          border-top: 1px dashed;
          top: 195px;
          opacity: 1;
        }
      }
    }
  }

  .contact {
    .contacts {
      flex-direction: column;
      align-items: center;
    }
  }
}

// ---アニメーション---

@keyframes bggradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

.area {
  z-index: 2;
  background: #ebebeb;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: var(--animeBoxColor);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.arrow-1-down {
  --size: 24px;
  --color: currentColor;
  --animation-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
  --animation-duration: 2s;
  position: relative;
  width: var(--size);
  height: var(--size);
  overflow-y: hidden;
}

.arrow-1-down::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--color);
  clip-path: polygon(
    0% 50%,
    50% 100%,
    100% 50%,
    75% 50%,
    75% 0%,
    25% 0%,
    25% 50%,
    0% 50%
  );
  transform: translateY(-100%);
  animation: var(--animation-timing-function) var(--animation-duration) infinite
    arrow-1-down-animation;
}

@keyframes arrow-1-down-animation {
  0% {
    transform: translateY(-100%);
  }

  45% {
    transform: translateY(0%);
  }

  55% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}
