* {
  margin: 0;
  padding: 0;
  overflow: hidden visible;
}

a {
  color: black;
  text-decoration: none;
}
